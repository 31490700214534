import * as React from 'react';
import SvgImage from './image/svg-image';

const Information = React.memo(() => {
  return (
    <article id="commutas-information">
      <div className="title-bg anime-up">
        <div className="title-flex sp-none">
          <p className="xxx-wrapper">
            <SvgImage filename="xxx" alt="×××" />
          </p>
          <h2 className="text-zen-maru">お知らせ</h2>
          <p className="xxx-wrapper">
            <SvgImage filename="xxx" alt="×××" />
          </p>
        </div>
        <p className="title-supplement sp-none">information</p>
        <h2 className="title-image-wrapper pc-none">
          <SvgImage filename="ttl_sp_info" alt="お知らせ" />
        </h2>
      </div>

      <div className="information-lists anime-up">
        <ul>
          <li>
            <div className="info-label info">お知らせ</div>
            <p className="info-body">
              自治体・公共Week2024（6/26〜6/28）の地域防災Expoに出展しました。詳細は
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.publicweek.jp/ja-jp/search/2024/directory/directory-details.%E3%82%A8%E3%83%97%E3%82%BD%E3%83%B3%E3%82%A2%E3%83%B4%E3%82%A1%E3%82%B7%E3%82%B9%20%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE.org-6d026129-856e-43b1-afaf-9f41c191ac64.html#/"
              >
                こちら
              </a>
              をご確認ください。
            </p>
          </li>
          <li>
            <div className="info-label info">お知らせ</div>
            <p className="info-body">
              コミュたすを活用した上田市のスマートシティ化の取り組みが「Digi田（デジでん）甲子園2023」の
              <br className="sp-none" />
              本選へ出場しています！　
              <a href="https://www.cas.go.jp/jp/seisaku/digitaldenen/koshien/honsen/2023/0016.html">
                事例紹介の動画
              </a>
              もご確認頂き、
              <a href="https://www.cas.go.jp/digiden2023_vote.html">web投票</a>
              にもご協力お願いいたします。
            </p>
          </li>
          <li>
            <div className="info-label event">イベント</div>
            <p className="info-body">
              東京国際消防防災展2023（6/15〜6/18）、自治体•公共Week2023（6/28〜6/30）の両展示会に
              <br className="sp-none" />
              コミュたすを出展しました。　展示会出展の詳細情報は
              <a href="https://avasys.jp/blog/article/000286.html">こちら</a>
              をご確認ください。
            </p>
          </li>
        </ul>
      </div>
    </article>
  );
});

Information.displayName = 'Information';

export default Information;
